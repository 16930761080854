import React, { useEffect } from 'react';
import styled from 'styled-components';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Box, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const MuiFormControl = styled(FormControl)``;

const SelectText = styled(Typography)`
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    @media (min-width: 960px) {
        margin-bottom: 0;
    }
`;

const SelectContainer = styled(Box)`
    display: block;
    /* margin: 2rem 0rem; */
    margin-top: 1rem;
    @media (min-width: 960px) {
        display: flex;
        align-items: baseline;
    }
`;

const MuiSelect = styled(Select)`
    border-radius: 0;
    min-width: 10rem;
    @media (min-width: 960px) {
        margin-top: 1rem;
    }

    & .MuiOutlinedInput-input {
        padding: 0.8rem 1rem 0.6rem 1rem;
        @media (min-width: 960px) {
            min-width: 10rem;
        }
    }
`;

function RoomSelect(props) {
    const [room, setRoom] = useState(props.chosenRoom);
    const [dateInUrl, setDateInUrl] = useState('');

    const handleOnChange = (event) => {
        console.log('show tartget', event.target.value);
        props.getSelectedRoom(event.target.value);
        setRoom(event.target.value);
        let path;
        if (window.location.href.includes('booking-kalender')) {
            path = 'booking-kalender';
        } else {
            path = 'aktivitets-kalender';
        }
        if (dateInUrl) {
            props.history.push(
                `/${path}/${event.target.value}?date=${dateInUrl}`
            );
        }
    };

    // ComponentDidMount
    useEffect(() => {
        if (props.chosenRoom) {
            // Check if it is an activity calendar, or booking calendar
            let path;
            if (window.location.href.includes('booking-kalender')) {
                path = 'booking-kalender';
            } else if (window.location.href.includes('aktivitets-kalender')) {
                path = 'aktivitets-kalender';
            } else {
                path = 'kalender';
            }
            // Get query
            let date;
            const search = props.location.search;

            date = new URLSearchParams(search).get('date');
            if (!date) {
                date = moment(new Date()).format('YYYY-MM-DD');
            }
            setDateInUrl(date);
            //Change path in url according to room
            props.history.push(`/${path}/${props.chosenRoom}?date=${date}`);
        }
        // eslint-disable-next-line
    }, [room]);

    useEffect(() => {
        setRoom(props.chosenRoom);
    }, [props.chosenRoom]);

    return (
        <SelectContainer>
            <SelectText>Skift lokale:</SelectText>
            <MuiFormControl variant="outlined">
                <MuiSelect onChange={handleOnChange} value={room}>
                    <MenuItem value="1">Lokale 1</MenuItem>
                    <MenuItem value="2">Lokale 2</MenuItem>
                    <MenuItem value="3">Lokale 3</MenuItem>
                    <MenuItem value="4">Lokale 4</MenuItem>
                    <MenuItem value="5">Lokale 5</MenuItem>
                    <MenuItem value="6">Lokale 6</MenuItem>
                    <MenuItem value="7">Lokale 7</MenuItem>
                    <MenuItem value="8">Lokale 8</MenuItem>
                </MuiSelect>
            </MuiFormControl>
        </SelectContainer>
    );
}

export default withRouter(RoomSelect);
