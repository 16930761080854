import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import ClickableLink from '../components/buttons/clickable-link/ClickableLink';
import { returnAuthenticated } from '../context/getData';

const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;
const Answer = styled(Typography)`
    margin-bottom: 1.2rem;
    width: 80%;

    &.qa-colourful-question {
        color: ${(props) => props.theme.palette.colors.orange};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const MobileSpace = styled.span`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const QAData = [
    {
        id: 1,
        question: 'Værdier og vision',
        answers: (
            <Answer variant="body2">
                Visionen for Bülowsvej 10 er at være en langsom, analog og klar
                ramme og vært for alle som har lyst til og finder det
                meningsfuldt, at være her.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Vi ønsker at være værter for mange forskellige mennesker og
                aktiviteter, men er ikke nødvendigvis det rigtige sted for alle
                mennesker og aktiviteter. Vi stiller nogle krav til vores
                brugere og gæster.
            </Answer>
        )
    },
    {
        id: 2,
        question: 'Lej lokale',
        answers: (
            <Answer variant="body2">
                Vi har pt. 8 lokaler til udlejning. Seks mindre fra 8 til 14 m2
                og tre større fra 30 til 45 m2. Det ene lokale kan ved særlige
                lejligheder udvides til ca. 65 m2.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Lokalerne kan f.eks. bruges til undervisning, møder, foredrag,
                bevægelse, øvning, kontor, terapi, massage, fest med mere. Vi
                har lyddøre, men huset er generelt bedst egnet til akustiske
                aktiviteter. Dog kan der sagtens bruges stereoanlæg, så længe
                vinduerne holdes lukkede.
            </Answer>
        )
    },
    {
        id: 3,
        question: 'Musikermedlemskab',
        answers: (
            <>
                <Answer variant="body2">
                    Da der er en del musikere der øver og/eller underviser i
                    huset, har vi en særlig mulighed for dem.
                    Musikermedlemskabet er relevant for nogle musikere og
                    musiklærere, men ikke for alle. <br />
                    <MobileSpace>
                        <br />
                    </MobileSpace>{' '}
                    Vi har tre typer medlemskab et for dem der bare vil øve selv
                    (C), et for dem der vil undervise (B) og et for dem der
                    enten vil øve sammen med andre og/eller undervise (A).{' '}
                    <br />
                    <MobileSpace>
                        <br />
                    </MobileSpace>{' '}
                    Vi har desværre kun mulighed for at optage et begrænset
                    antal medlemmer til A og B medlemskaber, derfor skal der
                    ansøges om medlemskab via en kontaktformular på hjemmesiden.{' '}
                    <br />I kontaktformularen skal du skrive hvilke
                    instrument(er) du spiller og underviser i. Du skal også
                    skrive om du underviser soloelever eller flere på en gang.{' '}
                    <br />
                    <MobileSpace>
                        <br />
                    </MobileSpace>{' '}
                    Hvis du ikke kan få et medlemskab, er du stadig velkommen
                    til at leje lokaler på lige fod med andre. <br />
                    <br />
                    Du kan altid skifte medlemstype eller opsige til udgangen af
                    måneden.
                    {/* <UnderlinedText>
                        {returnAuthenticated() ? (
                            <ClickableLink wantsMembership={true} text="her" />
                        ) : (
                            <ClickableLink
                                pathname="/oplysninger"
                                fromPath="/faq"
                                fromTitle="FAQ"
                                text="her"
                            />
                        )}
                    </UnderlinedText> */}
                    <br />
                    <br />
                    <Typography variant="subtitle1">Medlemskab A</Typography>
                    <ul>
                        <li>
                            Giver adgang til at øve på akustisk instrument eller
                            sang enten alene eller sammen med andre i et af
                            vores udlejningslokaler.
                        </li>
                        <li>
                            Lokaleadgangen gælder 24/7 til ikke udlejede lokaler
                            i hele medlemsperioden.
                        </li>
                        <li>
                            På kalenderen på denne hjemmeside, kan du løbende
                            følge med i hvilke lokaler der er ledige.
                        </li>
                        <li>
                            Hvis der foregår undervisning i lokalet, hvor der er
                            betaling involveret, skal lokalet lejes.
                        </li>
                        <li>
                            Hvis der foregår undervisning i lokalet, skal du
                            have en offentlig brugerprofil på opslagstavlen med
                            billede og en kort beskrivelse af hvad du underviser
                            i.
                        </li>
                        <li>
                            Dette gælder uanset om du optager nye elever eller
                            ej, dette bedes du også tage med i beskrivelsen.
                        </li>
                        <li>Medlemskab A giver 50% rabat på lokalelejen.</li>
                        <li>Medlemskab A koster 500 kr. per måned.</li>
                        <li>
                            Da vi har et begrænset antal medlemskaber af denne
                            type, skal medlemskab A godkendes af
                            administrationen.
                        </li>
                        <li>
                            Hvis godkendelsen falder midt i måneden, betales der
                            kun for de resterende dage i måneden, systemet har
                            udgangspunkt i en 30 dages måned og runder op til
                            nærmeste krone.
                        </li>
                        <li>
                            Derefter betales der 500 kr. per måned uanset
                            antallet af dage i måneden.
                        </li>
                    </ul>
                    <hr />
                    <br />
                    <br />
                    <Typography variant="subtitle1">Medlemskab B</Typography>
                    <ul>
                        <li>
                            Giver adgang til at soloøve på akustisk instrument
                            eller sang i et af vores udlejningslokaler.
                        </li>
                        <li>
                            Lokaleadgangen gælder 24/7 til ikke udlejede lokaler
                            i hele medlemsperioden.
                        </li>
                        <li>
                            På kalenderen på denne hjemmeside, kan du løbende
                            følge med i hvilke lokaler der er ledige.
                        </li>
                        <li>
                            Hvis der foregår undervisning i lokalet, hvor der er
                            betaling involveret, skal lokalet lejes.
                        </li>
                        <li>
                            Hvis der foregår undervisning i lokalet, skal du
                            have en offentlig brugerprofil på opslagstavlen med
                            billede og en kort beskrivelse af hvad du underviser
                            i.
                        </li>
                        <li>
                            Dette gælder uanset om du optager nye elever eller
                            ej, dette bedes du også tage med i beskrivelsen.
                        </li>
                        <li>
                            Hvis der er mere end en til stede i lokalet for at
                            øve sammen, skal lokalet også lejes.
                        </li>
                        <li> Medlemskab B giver 50% rabat på lokalelejen.</li>
                        <li> Medlemskab B koster 400 kr. per måned.</li>
                        <li>
                            Da vi har et begrænset antal medlemskaber af denne
                            type, skal medlemskab B godkendes af
                            administrationen.
                        </li>
                        <li>
                            Hvis godkendelsen falder midt i måneden, betales der
                            kun for de resterende dage i måneden, systemet har
                            udgangspunkt i en 30 dages måned og runder op til
                            nærmeste krone.
                        </li>
                        <li>
                            Derefter betales der 400 kr. per måned uanset
                            antallet af dage i måneden.
                        </li>
                    </ul>
                    <hr />
                    <br />
                    <br />
                    <Typography variant="subtitle1">Medlemskab C</Typography>
                    <ul>
                        <li>
                            Giver adgang til at soloøve på akustisk instrument
                            eller sang i et af vores 7 udlejningslokaler.
                        </li>
                        <li>
                            Lokaleadgangen gælder 24/7 til ikke udlejede
                            lokaler.
                        </li>
                        <li>
                            På kalenderen på denne hjemmeside, kan du løbende
                            følge med i hvilke lokaler der er ledige.
                        </li>
                        <li>
                            Hvis der foregår undervisning i lokalet eller i er
                            flere end en person der øver, skal lokalet lejes på
                            almindelige vilkår.
                        </li>
                        <li> Medlemskab C koster 300 kr. per måned.</li>
                        <li>
                            {' '}
                            Da vi har et begrænset antal medlemskaber af denne
                            type, skal medlemskab B godkendes af
                            administrationen.
                        </li>
                    </ul>
                </Answer>
            </>
        )
    },
    {
        id: 4,
        question: 'Fællesarealer',
        answers: (
            <Answer variant="body2">
                Indendørs består fællesarealerne af entré, toiletter, stue,
                skærmrum og gangarealet på første sal. Alle udendørs arealer
                betragtes som fællesareal. Eneste undtagelse er indhegningen
                omkring det lille japanske ahorn, der er ikke adgang for gæster,
                hverken børn eller voksne.
                <br />
                <br />
                Fællesarealer kan frit benyttes af alle medlemmer og lejere
                24/7. Dog kan gangarealet på første sal ikke bruges til at
                ophold sig eller opbevaring af genstande. Skærmrummet i det
                gamle køkken, er det eneste sted på fællesarealerne hvor der må
                bruges computere og telefoner. I skærmrummet er der kun adgang
                for voksne over 18 år.
                <br />
                <br />
                Vi som værter, beder alle vores gæster sætte mobilen på lydløs,
                når de træder ind på matriklen. Døråbning og korte nødvendige
                samtaler er muligt. Det er et analogt hus med mange børn, som vi
                prioriterer at give et fristed med nærværende voksne og børn.
                Dette gælder alle gæster der kommer i huset. Vi opfordrer også
                vores lejere til at undgå børn og voksne bruger skærme i de
                lejede lokaler, undtagen til arbejde.
            </Answer>
        )
    },

    // {
    //     id: 5,
    //     question: 'Lej lokale (ikke medlem)',
    //     answers: (
    //         <Answer variant="body2">
    //             <Typography variant="body2">
    //                 Lokale 1 og 2: 250/125 kr. i timen
    //                 <br />
    //                 Lokale 3: 200/100 kr. i timen <br />
    //                 Lokale 4 til 9: 60/30 kr. i timen
    //             </Typography>
    //             <br />
    //             Lej lokale{' '}
    //             <UnderlinedText>
    //                 <ClickableLink
    //                     pathname="/booking-kalender"
    //                     fromPath="/faq"
    //                     fromTitle="FAQ"
    //                     text="her."
    //                 />
    //             </UnderlinedText>
    //         </Answer>
    //     )
    // },
    // {
    //     id: 6,
    //     question: 'Lej lokale (medlem)',
    //     answers: (
    //         <Answer variant="body2">
    //             50% rabat på leje for medlemmer.  <br />
    //             Du skal være medlem i hele perioden fra reservation til og med
    //             lejeperioden.
    //         </Answer>
    //     )
    // },
    {
        id: 7,
        question: 'Årshjul',
        answers: (
            <Answer variant="body2">
                Lokalelejen er sæsonbetonet, det vil sige, der kan højest
                reserveres lokale en sæson ad gangen. <br />
                En sæson løber fra 1. juli til 30. juni. I januar måned
                reserverer hovedlejeren Den Lille Musikskole alle de tidsrum som
                det skønnes maksimalt skal bruges. <br /> <br />
                I perioden 1. februar til 30. april frigives de ledige tider til
                alle medlemmer og nuværende lejere. <br />
                Fra 1. maj frigives de resterende tider for alle. <br /> <br />
                Hen over sommeren og i starten af efteråret frigives yderligere
                reservationer, musikskolen ikke skal bruge alligevel.
            </Answer>
        )
    },

    {
        id: 8,
        question: 'Annullering',
        answers: (
            <Answer variant="body2">
                Du kan altid annullere en lokalereservation, det vil dog koste
                dig mellem 0 og 50 % af lokalelejen, alt efter hvornår du
                annullerer og om du er medlem eller ej:
                <br />
                <br />
                Hvis du annullerer mere end én uge før lejetidspunktet, opkræves
                medlemmer ingen leje, ikke medlemmer opkræves 25% af lejen.
                <br />
                <br /> Hvis du annullerer mindre end én uge før lejetidspunktet,
                opkræves medlemmer 25% af lejen, ikke medlemmer opkræves 50% af
                lejen.
            </Answer>
        )
    },
    {
        id: 9,
        question: 'Husregler',
        answers: (
            <Answer variant="body2">
                <ul>
                    <li>
                        Alle, der færdes i huset, skal tage deres sko af i
                        garderoben eller bruge overtræk eller hjemmesko.
                    </li>
                    <li>
                        Alle udendørs og indendørs fællesarealer er skærmfri
                        områder. Undtagelsen er det særlige skærmrum, hvor der
                        kun er adgang for voksne over 18 år. Dette rum benyttes
                        også til lytning af podcast, lydbøger og lignende, samt
                        læsning i elektroniske bøger og brug af mobiltelefon
                        (som gerne må være kort og diskret). Vi henstiller til
                        at mobilen sættes på lydløs, når du træder ind på
                        matriklen. Og slet ikke bruger nogen enheder til surfing
                        og spil med mere. På denne måde er du som gæst med til
                        at opretholde værternes ønsker for vores sted.
                    </li>
                    <li>
                        Ingen gæster, elever, klienter, kunder må opholde sig i
                        udlejningslokaler uden medlemmet eller lejeren er til
                        stede.
                    </li>
                    <li>
                        Ingen gæster, elever, klienter, kunder må opholde sig i
                        andre udlejningslokaler end det lejede.
                    </li>
                    <li>
                        Al undervisningsmateriale, instrumenter, rekvisitter og
                        andet udstyr der befinder sig i udlejningslokalerne, som
                        ikke er nævnt i næste afsnit, må kun benyttes efter
                        direkte aftale med Den Lille Musikskole.
                    </li>
                    <li>De farvede taburetter må kun benyttes af børn.</li>
                    <li>
                        Støjende aktiviteter skal altid udøves for lukkede
                        vinduer og døre.
                    </li>
                    <li>Der skal luftes ud inden lokalet forlades.</li>
                    <li>
                        Klaverlåg lukkes ned og alle møbler, samt inventar
                        stilles pænt på plads.
                    </li>
                    <li>
                        Affald bæres ned i køkkenet til vores affaldssortering.
                    </li>
                    <li>Hele matriklen holdes røgfri.</li>
                    <li>
                        Udendørs ophold og aktiviteter må ikke være til gene for
                        naboerne.
                    </li>
                    <li>
                        Instrumenter, inventar m.m. må ikke fjernes fra huset
                        uden forudgående aftale med Den Lille Musikskole.
                    </li>
                </ul>
                <br />
                *Vi opfordrer til, men håndhæver ikke, at børn under 18 år
                heller ikke bruger skærme i udlejningslokalerne.
            </Answer>
        )
    },
    {
        id: 10,
        question: 'Opslagstavlen',
        answers: (
            <Answer variant="body2">
                Opslagstavlen er en gratis og valgfri ekstra service for alle
                brugerne af bulowsvej10.dk. Det kræver kun et login enten et
                medlemskab, eller en fremtidig lokaleleje eller intention om
                enten et fremtidigt medlemskab og/eller lokaleleje.
                <br />
                <br />
                På opslagstavlen kan du oprette et opslag, hvor du præsenterer
                dig selv og hvad du tilbyder. Det kan være undervisning,
                behandling, terapi med mere.
                <br />
                <br />
                Sådan gør du:
                <ol>
                    <li>
                        Giv dit opslag en kort og præcis titel (eks.
                        Guitarundervisning, bodySDS, samtaleterapi etc.) Beskriv
                        din undervisning / dit tilbud så præcist og beskrivende
                        som muligt. Der er plads til 700 tegn.{' '}
                    </li>
                    <li>
                        Tilføj kontaktoplysninger og eventuelt links til sociale
                        medier. Du vælger selv, hvordan du vil kontaktes, din
                        mail og telefonnummer er dog skjult for søgemaskiner, så
                        det kræver ekstra handling fra besøgende, at få det
                        synligt.
                    </li>
                    <li>
                        Upload op til 5 billeder af dig selv, dit instrument,
                        sessions etc. Vælg en eller flere kategorier for dit
                        opslag.{' '}
                    </li>
                </ol>
                NB! Den Lille Musikskole har intet ansvar for den undervisning,
                som brugerne af opslagstavlen tilbyder.
            </Answer>
        )
    },
    {
        id: 11,
        question: 'Adgang til huset',
        answers: (
            <Answer variant="body2">
                Ved indgåelse af medlemsaftale og/eller lejeaftale tildeles der
                adgang til huset via brugerens mobiltelefon.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Det er ikke udlejerens ansvar, hvis medlemmet/lejeren ikke kan
                få adgang pga. manglende mobiltelefon.
                <br />
                <br />
                Adgang til huset sker pt. via bagdøren.
                <br />
                <br />
                Døren står permanent låst og kan kun åbnes via ”lås op” knappen,
                når du er logget ind på hjemmesiden og er fysisk tæt på døren.
                <br />
                <br />
                Døren har dørpumpe og lukker af sig selv. Det er ikke tilladt at
                holde døren åben med nogen genstande. Døren må kun åbnes og
                holdes fysisk åben af personer.
                <br />
                <br />
                ’Lås op’ -knappen virker kun når du har et gyldigt medlemskab
                eller en lejeaftale.
                <br />
                <br />
                For lejere, der ikke er medlemmer, er der adgang til bygningen
                og fællesarealerne 15 minutter før en påbegyndt lejeperiode.
            </Answer>
        )
    },
    {
        id: 12,
        question: 'Parkering',
        answers: (
            <Answer variant="body2">
                Biler kan parkeres i gården mod betaling via parkeringsapp
                (Easypark, OK, ParkPark).
                <MobileSpace>
                    <br />
                </MobileSpace>
                Områdenummeret er 118.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Der er automatisk kameraovervågning, som registrerer nummerplade
                ved ind- og udkørsel. Regler og vilkår fremgår af skiltningen på
                pladsen.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Udlejeren har intet ansvar for eventuelle afgifter ved manglende
                betaling for parkeringen.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Medlemmer og lejere forpligter sig til at parkere cykler foran
                huset ud mod Bülowsvej, samt at sikre at eventuelle elever,
                deltagere, kunder, klienter og andre gæster gør det samme.
                <MobileSpace>
                    <br />
                </MobileSpace>
            </Answer>
        )
    },
    {
        id: 13,
        question: 'Beliggenhed og transport',
        answers: (
            <Answer variant="body2">
                Huset ligger tæt ved Landbohøjskolen (med den skønne have) og
                Gl. Kongevej. Henholdsvis 450 og 800 meter til metrostationerne:
                Frederiksberg Allé (M3) og Frederiksberg (M1, M2, M3). Busser i
                nærheden: 9A, 31, 26 og 71.
            </Answer>
        )
    }
];

export default QAData;
