import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { returnAuthenticated } from '../../../context/getData';
import AskForLoginDialog from '../../dialogs/ask-for-login-dialog/AskForLoginDialog';
import NavHeadline from '../../headlines/NavHeadline';

const NavContainerComponent = styled.div`
    display: flex;

    width: max-content;
    align-content: flex-end;
`;

const BookLink = styled.span`
    cursor: pointer;
`;

function NavContainerDesktop(props) {
    const [askForLoginDialog, setAskForLoginDialog] = useState(false);
    const [activeMenu, setActiveMenu] = useState('');

    const onBookClick = () => {
        if (returnAuthenticated()) {
            props.history.push('/booking-kalender');
        } else {
            setAskForLoginDialog(true);
        }
    };

    useEffect(() => {
        // Check if the person is on a page which is present int he navbar

        if (window.location.pathname.includes('aktivitets-kalender')) {
            setActiveMenu('aktivitets-kalender');
        } else if (window.location.pathname.includes('booking-kalender')) {
            setActiveMenu('booking-kalender');
        } else if (window.location.pathname.includes('lokaler')) {
            setActiveMenu('lokaler');
        } else if (
            window.location.pathname.includes('opslagstavle') &&
            !window.location.pathname.includes('rediger-min-opslagstavle')
        ) {
            setActiveMenu('opslagstavle');
        }
        // eslint-disable-next-line
    }, [window.location.pathname]);

    return (
        <>
            <NavContainerComponent>
                <Box ml="2rem">
                    <Link to="/lokaler">
                        <NavHeadline title="Se lokaler" isActive={activeMenu} />
                    </Link>
                </Box>
                <Box ml="2rem">
                    <BookLink onClick={() => onBookClick()}>
                        <NavHeadline
                            title="Reserver lokale"
                            isActive={activeMenu}
                        />
                    </BookLink>
                </Box>

                <Box ml="2rem">
                    <Link to="/aktivitets-kalender">
                        <NavHeadline title="Kalender" isActive={activeMenu} />
                    </Link>
                </Box>

                <Box ml="2rem">
                    <Link to="/faq">
                        <NavHeadline title="FAQ" />
                    </Link>
                </Box>
                <Box ml="2rem">
                    <Link to="/opslagstavle">
                        <NavHeadline
                            title="Opslagstavle"
                            isActive={activeMenu}
                        />
                    </Link>
                </Box>
                {askForLoginDialog && (
                    <AskForLoginDialog
                        close={() => setAskForLoginDialog(false)}
                    />
                )}
            </NavContainerComponent>
        </>
    );
}

export default withRouter(NavContainerDesktop);
